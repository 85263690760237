import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const lostImage = data?.lostImage?.childImageSharp?.fluid
  const lostText = "You must be lost? this page does not exist"

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <div class="page-wrap d-flex flex-row align-items-center">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12 text-center">
              <span class="display-1 d-block">404</span>
              <div class="mb-4 lead">
                You must be lost? The page you are looking for was not found.
              </div>
              <Image fluid={lostImage} alt={lostText} />
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    lostImage: file(absolutePath: { regex: "/lost.jpg/" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
